#root {
  width: 100%;
}

/* hide burger menu
.ecmwf-template__dropdown.ecmwf-template__menu {
  display: none;
} */

a.ecmwf-template__logo {
  padding-left: 0.5em;
}

.App {
  text-align: center;
}

.section-header {
  text-align: center;
}

li.MuiTreeItem-root.popup-expanded {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}

div.MuiPaper-root {
  padding: 5px;
}